"use client";

import CurriculumModal from "@/components/CurriculumModal";
import { ArrowRightIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState } from "react";
import Image from "next/image";

const items = [
  {
    id: 1,
    title: "Curated Learning Path",
    description:
      "With numerous coding languages available, our Discovery Quiz ensures you’re learning the right coding languages that match perfectly to your unique goals. Our curriculum saves you valuable time by offering a clear study plan—no more confusion about what to learn next. Our curriculum allows you to gain the essential skills required to become a successful developer. ",
  },
  {
    id: 2,
    title: "Segmented Modules",
    description:
      "Navigate coding complexities effortlessly with our bite-sized learning modules. Designed for beginners, our easy-to-follow curriculum features practical examples and clear explanations. Learn from scratch, even if the coding lingo is new to you. ",
  },
  {
    id: 3,
    title: "Big Picture Explanations",
    description:
      "Code confidently with Code Flight's Big Picture explanations. The complexity of learning to code often lies in understanding where each piece fits. Our approach provides context with every new concept, offering insights into its real-life applications. Code Flight helps you connect the dots",
  },
  {
    id: 4,
    title: "Guided Coding Challenges",
    description:
      "Our Guided Challenges provide hands-on practice with clear instructions, ensuring hands-on experience at every step. This approach not only strengthens your skills but also eliminates the fear of mistakes, preparing you for the challenges of real-world coding.",
  },
  {
    id: 5,
    title: "Realistic Coding Projects",
    description:
      "These projects, reflective of real-world coding tasks, empower you to test, showcase, and enhance your skills. Commit your achievements to GitHub, feature them in your coding portfolio, and build a resume that you can be proud of!",
  },
  {
    id: 6,
    title: "Modularized AI Assistance",
    description:
      "Our Modularized AI Assistance ensures you're not alone in your coding journey. Learn to interact with an AI tutor, enhancing your problem-solving skills, and discover the art of asking the right questions, a crucial skill for any aspiring developer. ",
  },
];

const Curriculum = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeId, setActiveId] = useState<null | number>(null);

  useEffect(() => {
    if (activeId !== null) setIsModalOpen(true);
  }, [activeId]);

  const handleSetIsOpen = (open: boolean) => {
    setIsModalOpen(open);
    if (!open) setActiveId(null);
  };

  return (
    <div className="bg-secondary py-28 relative">
      <CurriculumModal
        isOpen={isModalOpen}
        handleSetIsOpen={handleSetIsOpen}
        title={activeId !== null ? items[activeId].title : ""}
        description={activeId !== null ? items[activeId].description : ""}
      />
      <div className="container mx-auto p-5 grid gap-10 relative">
        <h3 className="text-white font-semibold text-3xl lg:text-7xl">
          Curriculum Includes:
        </h3>
        <div className="z-10 absolute scale-50 lg:scale-100 -top-24 lg:-top-16 left-[60%]">
          <Image
            src="/arrow-5.png"
            width={356}
            height={350}
            alt=""
            className="object-contain"
          />
        </div>
        <p className="text-white max-w-3xl">
          Code Flight's curriculum goes beyond personalization. Tailored to your
          goals, it includes comprehensive content, engaging practice
          challenges, and realistic coding projects. These elements ensure you
          acquire the skills necessary for real-world development scenarios.
        </p>
        <div className="grid lg:grid-cols-3 gap-5 mt-10">
          {items.map((item, index) => (
            <button
              key={item.id}
              className="bg-light rounded-full py-3 flex gap-3 justify-center items-center"
              onClick={() => setActiveId(index)}
            >
              {item.title}
              <ArrowRightIcon className="w-5 h-5" />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Curriculum;
